import Loader from "components/common/Loader";
import { Card } from "components/common/v2/Card";
import { SWRContext } from "contexts/SWRContext";
import dynamic from "next/dynamic";

// Any Components leveraging react-big-calendar must be dynamically loaded on the client-side due to the size of the package.
const DynamicCalendarCardContent = dynamic(
    () => import("./CalendarCardContent").then(mod => mod.CalendarCardContent),
    {
        ssr: false,
        loading: () => (
            <div className="flex h-full w-full items-center justify-center">
                <Loader />
            </div>
        )
    }
);

export const CalendarCard = ({ className }: { className?: string }): JSX.Element => {
    return (
        <SWRContext>
            <Card className={className}>
                <DynamicCalendarCardContent />
            </Card>
        </SWRContext>
    );
};
