import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/nextjs";

import { getFullData, postDataWithStatus } from "utils/http-requests";
import { useAuth } from "hooks/use-auth";
import { URL_BANNERS_LIST } from "constants/urls";
import Banner from "./common/Banner";

type BannerType = {
    id: number;
    is_dismissable: boolean;
    name: string;
    text: string;
    type: string;
};

const bannerKeysMask = ["id", "is_dismissable", "name", "text", "type"];

export default function SystemBanners() {
    const { token, userProfile } = useAuth();
    const [bannersList, setBannersList] = useState<any[]>([]);
    const [bannersInProgress, setBannersInProgress] = useState<number[]>([]);

    useEffect(() => {
        getBannersList();
    }, []);

    useEffect(() => {
        if (bannersList.length) {
            bannersList.forEach(banner => {
                viewBanner(banner.id, banner.name);
            });
        }
    }, [bannersList]);

    const getBannersList = async () => {
        try {
            const {
                data: { results },
                response
            } = await getFullData(URL_BANNERS_LIST, token);
            //Filters response to display only valid banners and check response have all the required keys for each banner
            const filteredResults: BannerType[] = results?.filter((banner: BannerType) => {
                if (Object.keys(banner).length !== bannerKeysMask.length) {
                    Sentry.captureException(new Error("Banners format malformed"), {
                        contexts: {
                            details: {
                                advisorId: userProfile.id,
                                bannerId: banner.id || "banner id not found",
                                bannerName: banner.name || "banner name not found"
                            }
                        }
                    });
                    return false;
                } else {
                    return true;
                }
            });
            /*renders first 2 banners*/
            setBannersList(filteredResults.slice(0, 2));
        } catch (err) {
            Sentry.captureException(new Error("Banners format malformed"), {
                contexts: {
                    details: {
                        advisorId: userProfile.id
                    }
                }
            });
            console.error(err);
        }
    };

    const viewBanner = async (id: number, bannerName?: string) => {
        try {
            await postDataWithStatus(`${URL_BANNERS_LIST}${id}/view/`, {}, token).then(
                ({ response }) => {
                    if (response.status !== 200) {
                        Sentry.captureException(new Error("Unable to set as viewed"), {
                            contexts: {
                                details: {
                                    advisorId: userProfile.id,
                                    bannerId: id,
                                    bannerName: bannerName
                                }
                            }
                        });
                    }
                }
            );
        } catch (e) {
            console.error(e);
        }
    };
    const handleDismissBanner = async (id: number, bannerName?: string) => {
        try {
            setBannersInProgress([...bannersInProgress, id]);
            await postDataWithStatus(`${URL_BANNERS_LIST}${id}/dismiss/`, {}, token).then(
                ({ response }) => {
                    if (response.status !== 200) {
                        Sentry.captureException(new Error("Unable to dismiss banner"), {
                            contexts: {
                                details: {
                                    advisorId: userProfile.id,
                                    bannerId: id,
                                    bannerName: bannerName
                                }
                            }
                        });
                    }
                }
            );
        } catch (e) {
            console.error(e);
        } finally {
            setBannersInProgress([...bannersInProgress.filter(el => el !== id)]);
            getBannersList();
        }
    };

    return bannersList.length ? (
        <div className="flex flex-col w-full gap-4 md:gap-6 mb-4 md:mb-6 flex-shrink-0 empty:hidden">
            {bannersList.map(banner => {
                const isBannerLoading = !!bannersInProgress.find((el: any) => el === banner.id);
                return (
                    <div key={banner?.id}>
                        <Banner
                            type={banner?.type}
                            content={banner?.text}
                            isDisabled={isBannerLoading}
                            isDismissable={banner?.is_dismissable}
                            onDismiss={() => handleDismissBanner(banner?.id, banner?.name)}
                        />
                    </div>
                );
            })}
        </div>
    ) : (
        <></>
    );
}
