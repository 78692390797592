import { useSearchParams } from "next/navigation";

/** Returns the given url with the given search params appended, if any are present */
export const urlWithParams = (url: string, urlParams: URLSearchParams | null): string => {
    const str = urlParams?.toString() ?? "";
    return str ? `${url}?${str}` : url;
};

/** Returns the given url with the current page's search params maintained (if present) */
export const useUrlWithParams = (url: string): string => urlWithParams(url, useSearchParams());
