export enum EOnboardingStepStatus {
    CREATED = "Created",
    INCOMPLETE = "Incomplete",
    COMPLETED = "Completed"
}

interface IOnboardingGeneric<T1> {
    id?: number;
    slug: string;
    status: EOnboardingStepStatus;
    metadata: T1;
}

export type IOnboardingSessionStep = IOnboardingGeneric<{
    invitee_id?: string | null;
    event_id?: string | null;
    start_time?: string | null; // ISO 8601 date string. Can be null, especially for users who scheduled before this field was introduced.
    end_time?: string | null; // ISO 8601 date string. Can be null, especially for users who scheduled before this field was introduced.
}>;

// If we reintroduce additional types of onboarding steps in the future, add them here using | operator.
export type IOnboardingStep = IOnboardingSessionStep;
