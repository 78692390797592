import React, { HTMLAttributes, ReactNode } from "react";
import classNames from "classnames";
import { TabLayout } from "./TabNav/TabLayout";
import SystemBanners from "components/SystemBanners";
import { LayoutProps } from "../v1/Layout";

interface HeaderBannerLayoutProps extends LayoutProps {
    /** If header is not passed in, we'll use the title. (This is the most common use case.) */
    headerText?: string;
    rightOfHeaderContent?: ReactNode;
    breadcrumbs?: ReactNode;
    fullWidth?: boolean;
}

/** The title text for a portal page */
export const PageTitle = ({
    className,
    ...props
}: HTMLAttributes<HTMLHeadingElement>): JSX.Element => (
    <h1 {...props} className={classNames("font-medium text-headerFS24 text-primary", className)} />
);

export default function HeaderBannerLayout({
    children,
    title,
    headerText,
    rightOfHeaderContent,
    noPadding = false,
    breadcrumbs,
    fullWidth
}: HeaderBannerLayoutProps) {
    const displayTitle = headerText ?? title;

    return (
        <TabLayout
            title={title}
            fullWidth={fullWidth}
            className={noPadding ? "" : "px-4 tablet:px-0 py-6"}
            /* FIXME: Once we retire LayoutV1, which needed its own custom padding, we'll move this padding into TabLayout and
                no longer allow className to be passed in. It's not a great idea to allow people to change the classes of the foundational layout
                as those pages will be much harder to adjust if we make future changes to the app layout. */
        >
            <SystemBanners />
            {breadcrumbs && <div className="mb-4">{breadcrumbs}</div>}
            {(displayTitle || rightOfHeaderContent) && (
                <div className="w-full flex-shrink-0 flex items-start justify-between mb-4 tablet:mb-6">
                    {displayTitle && <PageTitle>{displayTitle}</PageTitle>}
                    {rightOfHeaderContent}
                </div>
            )}
            <div className="w-full flex-1">{children}</div>
        </TabLayout>
    );
}
